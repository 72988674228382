import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <section class="w-full px-6 pb-12 antialiased bg-white">
    <div class="mx-auto max-w-7xl">
        <div class="container max-w-lg px-4 py-8 mx-auto text-left md:max-w-none md:text-center">
                    <section class="hero container max-w-screen-lg mx-auto pb-4">
                <img class="mx-auto" src={`../naomi_chopra_1.png`} alt="Naomi Chopra" width={192} />
            </section>

            <h1 class="text-5xl font-extrabold leading-10 tracking-tight text-gray-900 text-center sm:leading-none md:text-6xl lg:text-7xl"><span class="inline md:block">Hi! I'm Naomi Chopra</span> <span class="relative mt-2 text-transparent bg-clip-text bg-gradient-to-br from-indigo-600 to-indigo-500 md:inline-block">Enterpreneur, Software Enthusiast</span></h1>
            <div class="mx-auto mt-5 text-gray-500 md:mt-8 md:max-w-lg text-center lg:text-lg">I'm currently the Co-founder and CEO of Hatica and enjoy making productivity software and helping teams drive engineering excellence 🌟</div>

            <div class="flex flex-col items-center mt-8 text-center">
                <span class="relative inline-flex w-full md:w-auto">
                    <a href="https://www.hatica.io" action="_blank" type="button" class="inline-flex items-center justify-center w-full px-8 py-4 text-base font-bold leading-6 text-white bg-indigo-600 border border-transparent md:w-auto hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 rounded-md">
                        Checkout Hatica
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 ml-2"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="7" y1="17" x2="17" y2="7"></line><polyline points="7 7 17 7 17 17"></polyline></svg>
                    </a>
                    <span class="absolute top-0 right-0 px-2 py-1 -mt-3 -mr-6 text-xs font-medium leading-tight text-white bg-green-400 rounded-full">Its awesome</span>
                </span>
                <a href="#contactForm" class="mt-3 text-md text-indigo-600">Contact me</a>
            </div>
            </div>
        </div>
    </section>

<section>
<div class="py-10 bg-white md:py-16" id="contactForm">
    <div class="px-10 mx-auto max-w-7xl md:px-16">
        <div class="max-w-3xl mx-auto mb-10 md:mb-16">
            <p class="text-xs font-bold text-indigo-500 uppercase">Contact me</p>
            <h2 class="mt-1 text-2xl font-bold text-left text-gray-800 lg:text-3xl md:mt-2">Need to reach me?</h2>
        </div>
        <form action="https://api.staticforms.xyz/submit" method="post" class="grid max-w-3xl gap-4 mx-auto sm:grid-cols-2">
            <input type="text" name="honeypot" style={{display:"none"}} />
            <input type="hidden" name="accessKey" value="e38180b5-9c55-400c-81ed-c208db288c0a" />
            <div class="sm:col-span-2">
                <label for="name" class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Name</label>
                <input name="name" class="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-indigo-300" />
            </div>
            <div class="sm:col-span-2">
                <label for="email" class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Email</label>
                <input name="email" class="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-indigo-300" />
            </div>

            <div class="sm:col-span-2">
                <label for="message" class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Message</label>
                <textarea name="message" class="w-full h-64 px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-indigo-300"></textarea>
            </div>

            <div class="flex items-center justify-between sm:col-span-2">
                <button class="inline-block px-8 py-3 text-sm font-semibold text-center text-white transition duration-100 bg-indigo-600 rounded-md outline-none hover:bg-indigo-500 active:bg-indigo-700 ring-indigo-300 md:text-base">Send Message</button>
            </div>
            <input type="hidden" name="redirectTo" value="https://www.naomichopra.com?submit=success" />
        </form>
        <p class="max-w-3xl mx-auto mt-5 text-xs text-gray-400">
            Please allow me 24-48 hours to respond.
        </p>
    </div>
</div>
</section>

<section class="w-full pt-24 pb-32 bg-white">
    <div class="relative px-12 mx-auto max-w-7xl">

        <div class="relative grid items-center grid-cols-1 gap-10 p-12 overflow-hidden shadow-2xl md:p-16 lg:p-20 xl:p-24 bg-gradient-to-r from-indigo-600 to-indigo-700 md:grid-cols-5 lg:gap-0 rounded-md">

            <div class="absolute top-0 right-0">
                <svg viewBox="0 0 487 487" class="object-cover w-full h-full" xmlns="http://www.w3.org/2000/svg"><path d="M487 486.7c-66.1-3.6-132.3-7.3-186.3-37s-95.9-85.3-126.2-137.2c-30.4-51.8-49.3-99.9-76.5-151.4C70.9 109.6 35.6 54.8.3 0H487v486.7z" fill="#FFF" fill-rule="nonzero" fill-opacity=".1"></path></svg>
            </div>

            <div class="absolute bottom-0 left-0 h-full">
                <svg viewBox="0 0 487 487" class="w-auto h-full opacity-75 object-stretch" xmlns="http://www.w3.org/2000/svg"><path d="M0 .3c67 2.1 134.1 4.3 186.3 37 52.2 32.7 89.6 95.8 112.8 150.6 23.2 54.8 32.3 101.4 61.2 149.9 28.9 48.4 77.7 98.8 126.4 149.2H0V.3z" fill="#FFF" fill-rule="nonzero" fill-opacity=".1"></path></svg>
            </div>

            <div class="relative z-30 col-span-1 md:col-span-3">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                  <span className="block">Would you like to checkout Hatica?</span>
                  <span className="block">Schedule a demo with me</span>
                </h2>
                <p class="max-w-sm my-6 text-base font-normal text-indigo-400 xl:max-w-lg lg:pr-0 pr-7 lg:text-xl">You can also request an invite to test drive Hatica yourself</p>
                <a href="https://www.hatica.io/demo" class="flex items-center justify-center w-full px-4 py-2 text-base font-medium leading-6 text-indigo-700 whitespace-no-wrap bg-white border-2 border-transparent shadow-sm md:w-auto md:inline-flex hover:bg-transparent hover:text-white hover:border-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-800 rounded-md">
                    Request Demo
                </a>
            </div>
            <div class="flex items-center h-full col-span-1 md:col-span-2">
                <img src="https://www.hatica.io/static/9305a9894a2b936e85c4840d98a8f9a7/d2ea0/footer-app.webp" class="relative top-0 right-0 object-cover w-full -mr-1 rounded md:max-w-sm md:w-auto max-w-none lg:absolute lg:max-w-md xl:max-w-lg lg:mt-9" alt="Dashboard" />
            </div>
        </div>
    </div>
</section>

  </Layout>
)

export default IndexPage
